import {
  CheckUser,
  createUser,
  patchUser,
  getUsers,
  getUser,
  deleteUser,
  loginUser,
  forgotPassword,
  registerAccount,
  assignProductToUser,
  unAssignProductToUser,
  updatePassword,
  refreshToken,
  deleteAccount,
  unregister
} from './user';
import { getAllContacts, createContact, getContact, updateContact, deleteContact } from '~/api/contact';
import { getEquipment, patchEquipment, deleteEquipment, addEquipment, getEquipmentImages } from '~/api/equipment';
import {
  addEvent,
  getEvent,
  getEvents,
  patchEvent,
  deleteEvent,
  postMessage,
  deleteMessage,
  assignEquipmentToEvent,
  unassignEquipmentToEvent
} from '~/api/event';

import {
  createFile,
  updateFile,
  deleteFile,
  getFile,
  getAllFiles,
  zipFiles,
  createFileContactLink,
  deleteFileContactLink,
  getFileContacts,
  createFileEquipmentLink,
  deleteFileEquipmentLink,
  getFileEquipments
} from '~/api/file';

import {
  getOrganizations,
  getOrganization,
  deleteOrganization,
  patchOrganization,
  addOrganization,
  getContactsOrganization,
  addLogoOrganization
} from '~/api/organization';

import {
  getProducts,
  getProduct,
  getProductShareLink,
  deleteProduct,
  patchProduct,
  addProduct,
  getProductImages,
  getProductMatterportImages,
  getDocuments,
  activateMatterportScan,
  unactivateMatterportScan,
  getProductEquipments,
  getProductContacts,
  createProductContact,
  assignContact,
  unassignContact,
  setThumbnail,
  createProductContactLink,
  deleteProductContactLink
} from '~/api/product';

import { generateLink, generateQrCode } from '~/api/link';

import { getPageTickets, getPageTicketsDetail } from '~/api/pages';

import { addTag, getTag, patchTag, deleteTag } from '~/api/tag';

import {
  addAddress,
  getAddress,
  patchAddress,
  deleteAddress,
  getAllAddress,
  getBuildingsSameAdress
} from '~/api/address';

import {
  createBuilding,
  getBuilding,
  getAllBuilding,
  updateBuilding,
  deleteBuilding,
  getBuildingEvents,
  getBuildingImages,
  getBuildingDocuments,
  getBuildingAddresse,
  getBuildingEquipments,
  getBuildingProducts,
  createBuildingContact,
  getBuildingContacts,
  createBuildingContactLink,
  deleteBuildingContactLink,
  getBuildingMatterportImages
} from '~/api/building';

import { getDpe, getDpeList } from '~/api/ademe';

import {
  addMatterportScan,
  getMatterportScan,
  patchMatterportScan,
  deleteMatterportScan,
  getMatterportStats
} from '~/api/matterportScan';

import { referencial, equipmentreferencial } from '~/api/referencial';

import {
  createMissionOrder,
  searchMissionOrder,
  getTechnicians,
  getPhotographs,
  assignMissionOrder,
  updateMissionOrder,
  odmLink,
  scanAppointmentDate,
  deleteMissionOrder
} from '~/api/missionOrder';

import { sendMail } from './mails';

import {
  createPaymentMethod,
  subscribePlan,
  getBillings,
  getPaymentsMethodes,
  deletePaymentsMethodes,
  setPaymentMethodeDefault,
  getPlan
} from './payment';

export const api = {
  ademe: { get: getDpe, getList: getDpeList },
  address: {
    create: addAddress,
    update: patchAddress,
    get: getAddress,
    delete: deleteAddress,
    search: getAllAddress,
    buildingsProducts: getBuildingsSameAdress
  },
  building: {
    create: createBuilding,
    update: updateBuilding,
    get: getBuilding,
    delete: deleteBuilding,
    search: getAllBuilding,
    // other
    getEvents: getBuildingEvents,
    getImages: getBuildingImages,
    getDocuments: getBuildingDocuments,
    getAddress: getBuildingAddresse,
    getEquipments: getBuildingEquipments,
    getProducts: getBuildingProducts,
    getBuildingsSameAdress: getBuildingsSameAdress,
    getContacts: getBuildingContacts,
    // contacts
    contact: {
      create: createBuildingContact,
      get: getBuildingContacts,
      linkTo: createBuildingContactLink,
      unlinkTo: deleteBuildingContactLink
    },
    matterport: {
      getImages: getBuildingMatterportImages
    }
  },
  contacts: {
    // crud
    create: createContact,
    update: updateContact,
    get: getContact,
    delete: deleteContact,
    search: getAllContacts
  },
  equipments: {
    // crud
    create: addEquipment,
    update: patchEquipment,
    get: getEquipment,
    delete: deleteEquipment,
    getImages: getEquipmentImages
    //search: ?
  },
  events: {
    // crud
    create: addEvent,
    update: patchEvent,
    get: getEvent,
    delete: deleteEvent,
    search: getEvents,
    // message
    message: {
      create: postMessage,
      delete: deleteMessage
    },
    // equipment
    equipment: {
      assign: assignEquipmentToEvent,
      unassign: unassignEquipmentToEvent
    }
  },
  files: {
    // crud
    create: createFile,
    update: updateFile,
    get: getFile,
    delete: deleteFile,
    search: getAllFiles,
    // other
    zip: zipFiles,
    contactLink: {
      create: createFileContactLink,
      delete: deleteFileContactLink,
      get: getFileContacts
    },
    equipmentLink: {
      create: createFileEquipmentLink,
      delete: deleteFileEquipmentLink,
      get: getFileEquipments
    }
  },
  link: {
    // crud
    create: generateLink,
    createQrCode: generateQrCode
  },
  matterport: {
    create: addMatterportScan,
    update: patchMatterportScan,
    get: getMatterportScan,
    delete: deleteMatterportScan,
    stats: getMatterportStats
  },
  organizations: {
    // crud
    create: addOrganization,
    update: patchOrganization,
    get: getOrganization,
    delete: deleteOrganization,
    search: getOrganizations,
    addLogo: addLogoOrganization
    // allready in contact
    // contact: {
    //   create: addContact,
    //   search: getContacts
    //   // getContacts: getContactsOrganization ?
    // }
  },
  pages: {
    ticket: {
      get: getPageTickets,
      getDetail: getPageTicketsDetail
    }
  },
  product: {
    create: addProduct,
    update: patchProduct,
    get: getProduct,
    delete: deleteProduct,
    search: getProducts,
    // other
    getEvents: getBuildingEvents,
    getImages: getProductImages,
    getDocuments: getDocuments,
    // getAddress: getBuildingAddresse,
    getEquipments: getProductEquipments,
    // getProducts: getBuildingProducts,
    // getBuildingsSameAdress: getBuildingsSameAdress,
    getContacts: createProductContact,
    contact: {
      create: createProductContact,
      get: getProductContacts,
      assign: assignContact, // deprecated
      unassign: unassignContact, // deprecated
      linkTo: createProductContactLink,
      unlinkTo: deleteProductContactLink
    },
    equipments: {
      get: getProductEquipments
    },
    // files
    files: {
      getImages: getProductImages,
      getDocuments: getDocuments,
      setThumbnail: setThumbnail
    },
    // matterport
    matterport: {
      getImages: getProductMatterportImages
    },
    link: {
      // crud
      getShareLink: getProductShareLink
    }
  },
  referencial: {
    get: referencial,
    equipments: {
      get: equipmentreferencial
    }
  },
  tags: {
    create: addTag,
    get: getTag,
    update: patchTag,
    delete: deleteTag
  },
  users: {
    // crud
    create: createUser,
    update: patchUser,
    get: getUser,
    delete: deleteUser,
    search: getUsers,
    deleteAccount: deleteAccount,
    unregister: unregister,
    // product
    product: {
      assign: assignProductToUser,
      unAssign: unAssignProductToUser
    },
    //other
    login: loginUser,
    refresh: refreshToken,
    updatePassword: updatePassword,
    forgotPassword: forgotPassword,
    registerAccount: registerAccount,
    check: CheckUser
  },
  missionOrders: {
    create: createMissionOrder,
    update: updateMissionOrder,
    search: searchMissionOrder,
    getTechnicians: getTechnicians,
    getPhotographs: getPhotographs,
    assign: assignMissionOrder,
    odmLink: odmLink,
    scanAppointmentDate: scanAppointmentDate,
    delete: deleteMissionOrder
  },
  mails: {
    send: sendMail
  },

  payment: {
    createPaymentMethod: createPaymentMethod,
    subscribe: subscribePlan,
    getBillings: getBillings,
    getPaymentsMethodes: getPaymentsMethodes,
    deletePaymentsMethodes: deletePaymentsMethodes,
    setDefault: setPaymentMethodeDefault,
    getPlan: getPlan
  }
};
