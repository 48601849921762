import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Card, CardHeader, CardContent, useTheme } from '@mui/material';

export const BarsChart = ({ title, labels, data1, data2, info1, info2 }) => {
  const theme = useTheme();

  const dataset = labels.map((month, index) => ({
    month,
    [info1]: data1[index],
    [info2]: data2[index]
  }));

  return (
    <Card sx={{ borderRadius: '10px' }}>
      <CardHeader title={title} />
      <CardContent>
        <BarChart
          xAxis={[{ dataKey: 'month', scaleType: 'band' }]}
          series={[
            { dataKey: info1, label: info1, color: theme.palette.primary.main },
            { dataKey: info2, label: info2, color: theme.palette.secondary.main }
          ]}
          dataset={dataset}
          height={200} // Ajustez la hauteur du graphique selon vos besoins
        />
      </CardContent>
    </Card>
  );
};
