import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerAccount } from '~/api';
import { checkEmail, checkPassword } from '~/utils/check';
import { setSession } from '../AuthApi';
import Auth from '~/utils/context/Auth';
import '../Auth.scss';
import PaymentForm from '../../PaymentForm/PaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { MuiTelInput } from 'mui-tel-input';
import { CountryCode } from 'libphonenumber-js';

interface IRegister {
  compagny_name: string;
  street: string;
  zip_code: string;
  city: string;
  legal_number: string;
  vat_number: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  phone1: string;
  phone2: string;
  organization_kind: number;
  plan_kind: number;
  payment_method_id: string;
}
loadStripe.setLoadParameters({ advancedFraudSignals: false });

export const Register = ({ setSpinnerOpen, spinnerOpen }) => {
  const navigate = useNavigate();
  const initData = {
    compagny_name: '',
    street: '',
    zip_code: '',
    city: '',
    legal_number: '',
    vat_number: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone1: '',
    phone2: '',
    organization_kind: 1,
    plan_kind: 1,
    payment_method_id: ''
  };
  const [dataForm, setDataForm] = useState<IRegister>(initData);
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [submit, setSubmit] = useState<boolean>(false);
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState<boolean>(false);
  const [checkboxCGV, setCheckboxCGV] = useState<boolean>(false);
  const { setIsAuthenticated } = useContext(Auth);
  const [paymentStep, setPaymentStep] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<CountryCode>('FR');
  const [countryCode2, setCountryCode2] = useState<CountryCode>('FR');
  const { REACT_APP_STRIPE_API_KEY } = process.env;
  const [stripePromise, setStripePromise] = useState(null);

  const handleChangeCheckboxCGV = (event) => {
    setCheckboxCGV(event.target.checked);
  };

  const handleSubmitForm = () => {
    setSubmit(true);
    setEmailAlreadyUsed(false);
    if (
      dataForm.street &&
      dataForm.zip_code &&
      dataForm.city &&
      dataForm.first_name &&
      dataForm.last_name &&
      dataForm.phone1 &&
      // dataForm.plan_kind &&
      // dataForm.payment_method_id &&
      checkEmail(dataForm.email) &&
      checkPassword(dataForm.password) &&
      dataForm.password === passwordConfirm
    ) {
      registerAccount(dataForm)
        .then((resp) => {
          setSession(resp);
          setIsAuthenticated(true);
          navigate('/');
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    setSpinnerOpen(false);
  }, []);

  useEffect(() => {
    if (paymentStep) {
      loadStripe(REACT_APP_STRIPE_API_KEY).then((stripe) => {
        setStripePromise(stripe);
      });
    }
  }, [paymentStep]);

  return (
    <div className="Auth">
      <div className="Auth__header">
        <Box sx={{ mt: 4, mb: 4 }}>
          <img src="/logo3D.svg" height="50" alt="logo" />
        </Box>
        <Typography variant="h5" color={'primary'} sx={{ mb: 3 }}>
          Inscription
        </Typography>
      </div>

      {!paymentStep && (
        <>
          <div className="Auth__hr">
            <Typography variant="body1" color={'primary'} sx={{ mb: 2, textAlign: 'center' }}>
              Société
            </Typography>
            <Grid container spacing={1.5}>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Nom de l'entreprise"
                  fullWidth
                  error={submit && dataForm.compagny_name.length === 0}
                  helperText={submit ? (dataForm.compagny_name.length !== 0 ? '' : 'Nom non valide') : ''}
                  value={dataForm.compagny_name}
                  onChange={(e) => setDataForm({ ...dataForm, compagny_name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Adresse"
                  fullWidth
                  required
                  error={submit && dataForm.street.length === 0}
                  helperText={submit ? (dataForm.street.length !== 0 ? '' : 'Adresse non valide') : ''}
                  value={dataForm.street}
                  onChange={(e) => setDataForm({ ...dataForm, street: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Code postal"
                  fullWidth
                  required
                  error={submit && dataForm.zip_code.length === 0}
                  helperText={submit ? (dataForm.zip_code.length !== 0 ? '' : 'zip non valide') : ''}
                  value={dataForm.zip_code}
                  onChange={(e) => setDataForm({ ...dataForm, zip_code: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  label="Ville"
                  fullWidth
                  required
                  error={submit && dataForm.city.length === 0}
                  helperText={submit ? (dataForm.city.length !== 0 ? '' : 'Ville non valide') : ''}
                  value={dataForm.city}
                  onChange={(e) => setDataForm({ ...dataForm, city: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="SIREN"
                  fullWidth
                  required={false}
                  value={dataForm.legal_number}
                  onChange={(e) => setDataForm({ ...dataForm, legal_number: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="N° TVA"
                  fullWidth
                  required={false}
                  value={dataForm.vat_number}
                  onChange={(e) => setDataForm({ ...dataForm, vat_number: e.target.value })}
                />
              </Grid>
            </Grid>
            <hr />
          </div>
          <Grid container spacing={1.5}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Prénom"
                fullWidth
                required
                error={submit && dataForm.first_name.length === 0}
                helperText={submit ? (dataForm.first_name.length !== 0 ? '' : 'Nom non valide') : ''}
                value={dataForm.first_name}
                onChange={(e) => setDataForm({ ...dataForm, first_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nom"
                fullWidth
                required
                error={submit && dataForm.last_name.length === 0}
                helperText={submit ? (dataForm.last_name.length !== 0 ? '' : 'Nom non valide') : ''}
                value={dataForm.last_name}
                onChange={(e) => setDataForm({ ...dataForm, last_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Adresse email"
                fullWidth
                required
                error={(submit && !checkEmail(dataForm.email)) || emailAlreadyUsed}
                helperText={
                  emailAlreadyUsed
                    ? 'Email déjà utilisé'
                    : submit
                      ? checkEmail(dataForm.email)
                        ? ''
                        : 'Adresse email non valide'
                      : ''
                }
                type={'email'}
                value={dataForm.email}
                onChange={(e) => setDataForm({ ...dataForm, email: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mot de passe"
                fullWidth
                required
                type={'password'}
                error={submit && !checkPassword(dataForm.password)}
                helperText={submit ? (checkPassword(dataForm.password) ? '' : 'Mot de passe non valide') : ''}
                value={dataForm.password}
                onChange={(e) => setDataForm({ ...dataForm, password: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Confirmation de mot de passe"
                fullWidth
                required
                type={'password'}
                error={submit && passwordConfirm !== dataForm.password}
                helperText={submit ? (passwordConfirm === dataForm.password ? '' : 'Confirmation non valide') : ''}
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiTelInput
                label="Numéro de téléphone"
                fullWidth
                required
                error={submit && dataForm.phone1.length === 0}
                helperText={submit ? (dataForm.phone1.length !== 0 ? '' : 'Téléphone non valide') : ''}
                value={dataForm.phone1}
                onChange={(value, info) => setDataForm({ ...dataForm, phone1: info.numberValue })}
                defaultCountry={countryCode}
                forceCallingCode
                continents={['EU']}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiTelInput
                label="Téléphone fixe"
                fullWidth
                value={dataForm.phone2}
                onChange={(value, info) => setDataForm({ ...dataForm, phone2: info.numberValue })}
                defaultCountry={countryCode2}
                forceCallingCode
                continents={['EU']}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider sx={{ my: 2 }}></Divider>
            </Grid>
            <Grid item xs={12} md={1}>
              <FormControlLabel control={<Checkbox onChange={handleChangeCheckboxCGV} />} label="" />
            </Grid>
            <Grid item xs={12} md={11}>
              Je certifie avoir lu et accepté{' '}
              <a href="mentions-legales-cgv-cgu.pdf" target="_blanc" className="Auth__linkCGV">
                les Conditions générales d’utilisation et de vente
              </a>{' '}
              du site ainsi que la
              <a href="politique-confidentialite-audit-virtuel-052024.pdf" target="_blanc" className="Auth__linkCGV">
                {' '}
                Politique de Confidentialité
              </a>
            </Grid>
          </Grid>

          <Stack className="Auth__submit">
            <Button
              disabled={!checkboxCGV}
              variant="contained"
              size="medium"
              color={'primary'}
              onClick={handleSubmitForm}
              sx={{ mb: 2, px: '32px', py: '12px' }}
            >
              Valider
            </Button>
          </Stack>
          <Stack className="Auth__hr">
            <Divider sx={{ my: 2 }}>ou</Divider>
            <Button
              variant="outlined"
              color={'primary'}
              sx={{ mb: 2, px: '32px', py: '12px' }}
              onClick={() => {
                setSpinnerOpen(true);
                navigate('/login');
              }}
            >
              Se connecter
            </Button>
          </Stack>
        </>
      )}
      {/* {paymentStep && (
        <Elements stripe={stripePromise}>
          <PaymentForm setFormData={setDataForm} dataForm={dataForm} submitForm={handleSubmitForm} />
        </Elements>
      )} */}
    </div>
  );
};

export default Register;
