import { Card, CardHeader, CardContent, Box, Typography } from '@mui/material';

export const ListStats = ({ title, stats }) => {
  return (
    <Card sx={{ height: 298, maxHeight: 298, borderRadius: '10px' }}>
      <CardHeader title={title} />
      <CardContent>
        <Box display="flex" flexWrap="wrap" flexDirection={'column'} justifyContent="space-around">
          {stats.map((stat, index) => (
            <Box key={index} display="flex" alignItems={'center'} sx={{ ml: '25%', gap: '1vw' }}>
              <Typography variant="body2">{stat.value}</Typography>
              <Typography variant="h6">{stat.text}</Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
