import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  TablePagination,
  useTheme,
  useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ClearIcon from '@mui/icons-material/Clear';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { format } from 'date-fns';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { api } from '~/api/api';

const { REACT_APP_STRIPE_API_KEY } = process.env;

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const AddCardForm: React.FC<{ onClose: () => void; setPaymentId: React.Dispatch<React.SetStateAction<string>> }> = ({
  onClose,
  setPaymentId
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement!
    });

    if (error) {
      console.error(error);
    } else {
      console.log(paymentMethod);
      setPaymentId(paymentMethod.id);
      onClose();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }} disabled={!stripe}>
        Ajouter
      </Button>
      <Button onClick={onClose} variant="outlined" color="primary" style={{ marginTop: '16px', marginLeft: '15px' }}>
        Annuler
      </Button>
    </form>
  );
};

export const PaymentSettings: React.FC<{
  setSpinnerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  spinnerOpen: boolean;
}> = ({ setSpinnerOpen, spinnerOpen }) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [paymentId, setPaymentId] = useState('');
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  const rowsPerPage = 5;

  const queryClient = useQueryClient();

  const { data: bills, isLoading: isLoadingBills } = useQuery('bills', api.payment.getBillings);
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useQuery(
    'paymentMethods',
    api.payment.getPaymentsMethodes
  );
  const { data: plan, isLoading: isLoadingPlan } = useQuery('plan', api.payment.getPlan);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const mutation = useMutation(api.payment.createPaymentMethod, {
    onSuccess: () => {
      queryClient.invalidateQueries('paymentMethods');
    }
  });

  const handleDownloadInvoice = (invoiceUrl: string) => {
    const link = document.createElement('a');
    link.href = invoiceUrl;
    link.click();
  };

  const handleDefaultChange = async (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    await api.payment.setDefault(id);
    queryClient.invalidateQueries('paymentMethods');
  };

  const handleDeleteAccount = async () => {
    await api.users.unregister();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const dateFormat = format(date, 'dd/MM/yyyy');
    return dateFormat;
  };

  useEffect(() => {
    if (paymentId !== '') {
      mutation.mutate(paymentId);
      setPaymentId('');
    }
  }, [paymentId]);

  useEffect(() => {
    if (isLoadingBills || isLoadingPaymentMethods || isLoadingPlan) {
      setSpinnerOpen(true);
    } else {
      setSpinnerOpen(false);
    }
  }, [isLoadingBills, isLoadingPaymentMethods, isLoadingPlan, setSpinnerOpen]);

  const totalPages = Math.ceil((bills?.length || 0) / rowsPerPage);

  const handleDeletePaymentMethod = async (id: string) => {
    await api.payment.deletePaymentsMethodes(id);
    queryClient.invalidateQueries('paymentMethods');
  };

  const isExpired = (exp_month: number, exp_year: number) => {
    const today = new Date();
    const expDate = new Date(exp_year, exp_month - 1, 1);
    return today > expDate;
  };

  useEffect(() => {
    if (open)
      loadStripe(REACT_APP_STRIPE_API_KEY).then((stripe) => {
        setStripePromise(() => stripe);
      });
  }, [open]);

  return (
    <>
      {spinnerOpen ? (
        <div></div>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={5}>
            {bills?.length === 0 ? (
              <Box my={3}>
                <Typography variant="h6">Aucune facture disponible</Typography>
              </Box>
            ) : (
              <Box my={3}>
                <Typography variant="h6">FACTURES</Typography>
                <TableContainer sx={{ width: !matchesMd ? '80vw' : '50vw' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }}>Date</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Montant</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Télécharger</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bills?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((bill, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ textAlign: 'center' }}>{formatDate(bill.date)}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{bill.amount_due / 100}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <IconButton
                              onClick={() => handleDownloadInvoice(bill.invoice_pdf)}
                              style={{ justifyContent: 'center' }}
                            >
                              <FileDownloadIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={bills?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={({ from, to, count }) => `Page ${page + 1} sur ${totalPages}`}
                  />
                </TableContainer>
              </Box>
            )}
          </Grid>
          <Grid item xs={8}>
            {paymentMethods?.length === 0 ? (
              <Box my={2}>
                <Typography variant="h6">Aucun moyen de paiement disponible</Typography>
              </Box>
            ) : (
              <Box my={2}>
                <Typography variant="h6">MÉTHODE DE PAIEMENT</Typography>
                <TableContainer sx={{ width: !matchesMd ? '90vw' : '50vw' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }}>Type de Carte</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Numéro de carte</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Date d'expiration</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Par défaut</TableCell>
                        {paymentMethods?.length > 1 && <TableCell style={{ textAlign: 'center' }}>Supprimer</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentMethods?.map((paymentMethod) =>
                        isExpired(paymentMethod.exp_month, paymentMethod.exp_year) ? (
                          <TableRow key={paymentMethod.id}>
                            <TableCell style={{ textAlign: 'center' }}>
                              <Typography color="error">{paymentMethod.brand.toUpperCase()}</Typography>
                            </TableCell>
                            <TableCell color="error" style={{ textAlign: 'center' }}>
                              <Typography color="error">**** **** **** {paymentMethod.last4}</Typography>
                            </TableCell>
                            <TableCell color="error" style={{ textAlign: 'center' }}>
                              <Typography color="error">
                                {paymentMethod.exp_month}/{paymentMethod.exp_year} {'(Expirée)'}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              <Radio checked={paymentMethod.default} disabled style={{ justifyContent: 'center' }} />
                            </TableCell>
                            {paymentMethods?.length > 1 && (
                              <TableCell style={{ textAlign: 'center' }}>
                                <IconButton style={{ justifyContent: 'center' }}>
                                  <ClearIcon onClick={() => handleDeletePaymentMethod(paymentMethod.id)} />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        ) : (
                          <TableRow key={paymentMethod.id}>
                            <TableCell style={{ textAlign: 'center' }}>{paymentMethod.brand.toUpperCase()}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>**** **** **** {paymentMethod.last4}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {paymentMethod.exp_month}/{paymentMethod.exp_year}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              <Radio
                                checked={paymentMethod.default}
                                onChange={(event) => handleDefaultChange(event, paymentMethod.id)}
                                style={{ justifyContent: 'center' }}
                              />
                            </TableCell>
                            {paymentMethods?.length > 1 && (
                              <TableCell style={{ textAlign: 'center' }}>
                                <IconButton style={{ justifyContent: 'center' }}>
                                  <ClearIcon onClick={() => handleDeletePaymentMethod(paymentMethod.id)} />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button variant="contained" color="primary" style={{ marginTop: '16px' }} onClick={handleClickOpen}>
                  AJOUTER UNE CARTE DE PAIEMENT
                </Button>
                <Dialog open={open} onClose={handleClose} fullWidth>
                  <DialogTitle>Ajouter une carte de paiement</DialogTitle>
                  <DialogContent>
                    <Elements stripe={stripePromise}>
                      <AddCardForm onClose={handleClose} setPaymentId={setPaymentId} />
                    </Elements>
                  </DialogContent>
                </Dialog>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>
              <Typography variant="h6">ABONNEMENT</Typography>
              <Typography variant="body2" paragraph sx={{ mt: 1 }}>
                Type d'abonnement en cours : {plan?.kind === 0 ? 'Mensuel' : 'Annuel'}
              </Typography>
              <Link href="https://www.regir.fr/conditions" target="_blank" style={{ marginTop: '15px' }}>
                Consulter la grille tarifaire
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>
              <Typography variant="h6">CLOTURER MON ABONNEMENT</Typography>
              <Typography variant="body2" paragraph sx={{ mt: 1 }}>
                La clôture de l’abonnement prendra effet à la fin de la période en cours.
                <br />
                À partir de ce moment, les liens publics seront cassés, et l’ensemble des accès seront supprimés.
                <br />
                L’ensemble des documents et médias que vous aurez chargés sur la plateforme seront réunis dans un
                dossier d’archive
                <br />
                qui sera mis à votre disposition dans un délai d’un mois à compter de la date d’effet de la clôture du
                compte.
              </Typography>
              <Button
                variant="outlined"
                color="error"
                sx={{ borderColor: '#EF5A41', mt: 1 }}
                onClick={() => {
                  setOpenDeleteAccount(true);
                }}
              >
                CLOTURER MON COMPTE
              </Button>
            </Box>
          </Grid>

          {openDeleteAccount && (
            <Dialog open={openDeleteAccount} onClose={() => setOpenDeleteAccount(false)} fullWidth>
              <DialogTitle>Clôturer mon compte</DialogTitle>
              <DialogContent>
                <Typography variant="body2" paragraph>
                  Une fois votre demande de clôture prise en compte, nous reviendrons vers vous.
                  <br />
                  Êtes-vous sûr de vouloir clôturer votre compte ?
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ borderColor: '#EF5A41', mt: 1 }}
                    onClick={() => {
                      setOpenDeleteAccount(false);
                      handleDeleteAccount();
                    }}
                  >
                    CLOTURER MON COMPTE
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1 }}
                    onClick={() => {
                      setOpenDeleteAccount(false);
                    }}
                  >
                    ANNULER
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          )}
        </Grid>
      )}
    </>
  );
};
