import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { auth } from '~/app/components/Auth/AuthApi';

import { DoughnutChart } from '../../components/DoughnutChart/DoughnutChart';
import { Stats } from '../../components/Stats/Stats';
import { BarsChart } from '../../components/BarsChart/BarsChart';
import { LinesChart } from '~/app/components/LinesChart/LinesChart';
import { PiesChart } from '~/app/components/PiesChart/PiesChart';
import { ListStats } from '~/app/components/ListStats/ListStats';
import { DynamicDataGrid } from '~/app/components/DynamicDataGrid/DynamicDataGrid';

export const Dashboard = ({ setSpinnerOpen, spinnerOpen }) => {
  const theme = useTheme();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    setIsSuperAdmin(auth.isSuperAdmin());
  }, []);

  const labels = ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
  const data1 = [30, 45, 28, 50, 66, 75, 80, 70, 60, 55, 40, 45];
  const data2 = [40, 30, 35, 45, 50, 55, 60, 65, 70, 75, 80, 85];
  const data3 = [70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125];

  const dataPieChart = [
    { id: 0, value: 10, label: 'Terminés' },
    { id: 1, value: 15, label: 'En cours' },
    { id: 2, value: 20, label: 'A qualifier' }
  ];

  const dataListStats = [
    { value: '10%', text: 'Studio' },
    { value: '20%', text: 'T1' },
    { value: '30%', text: 'T2' },
    { value: '40%', text: 'T3' },
    { value: '36%', text: 'T4' },
    { value: '08%', text: 'T5' },
    { value: '12%', text: 'T6+' },
    { value: '03%', text: 'Maisons' }
  ];

  const dataListStatsDPE = [
    { value: '10%', text: 'A' },
    { value: '20%', text: 'B' },
    { value: '60%', text: 'C' },
    { value: '20%', text: 'D' },
    { value: '21%', text: 'E' },
    { value: '18%', text: 'F' },
    { value: '02%', text: 'G' },
    { value: '03%', text: 'Pas de DPE' }
  ];

  const sampleData = [
    { id: 1, Collaborateurs: 'John', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
    { id: 2, Collaborateurs: 'Pierre', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
    { id: 3, Collaborateurs: 'Marc', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
    { id: 4, Collaborateurs: 'Johnny', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
    { id: 5, Collaborateurs: 'Test', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
    { id: 6, Collaborateurs: 'Blabla', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
    { id: 7, Collaborateurs: 'Jack', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
    { id: 8, Collaborateurs: 'Jean', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 }
  ];

  useEffect(() => {
    setSpinnerOpen(false);
  }, []);

  return (
    <>
      {isSuperAdmin && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" sx={{ color: 'primary.main', paddingTop: '15px' }}>
              Tableau de bord
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <Stats
              title={'Nombre de biens'}
              amount={10}
              text={'Lots'}
              percentageColor={theme.palette.success.main}
              chipText={'+10%'}
              info={'ce mois-ci'}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <Stats
              title={'Nombre de biens'}
              amount={10}
              text={'Lots'}
              percentageColor={theme.palette.success.main}
              chipText={'+10%'}
              info={'ce mois-ci'}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <Stats
              title={'Nombre de biens'}
              amount={10}
              text={'Lots'}
              percentageColor={theme.palette.success.main}
              chipText={'+10%'}
              info={'ce mois-ci'}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <Stats
              title={'Nombre de biens'}
              amount={10}
              text={'Lots'}
              percentageColor={theme.palette.success.main}
              chipText={'+10%'}
              info={'ce mois-ci'}
            />
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <DoughnutChart value={75} title={'Commercialisation'} text={'Loué'} info1={'Loué'} info2={'Vide'} />
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <DoughnutChart value={75} title={'Commercialisation'} text={'Loué'} info1={'Loué'} info2={'Vide'} />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <PiesChart title={'Tickets'} data={dataPieChart} />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <LinesChart title={'Evolution du Portefeuille'} labels={labels} data={data3} info={'Cumul'} />
          </Grid>
          <Grid item xs={4} sm={2} lg={2}>
            <ListStats title={'Type de biens'} stats={dataListStats} />
          </Grid>
          <Grid item xs={4} sm={2} lg={2}>
            <ListStats title={'DPE'} stats={dataListStatsDPE} />
          </Grid>

          <Grid item xs={12} sm={12} lg={8}>
            <BarsChart
              title={'Evolution du Portefeuille'}
              labels={labels}
              info1={'Entrée'}
              data1={data1}
              info2={'Sortie'}
              data2={data2}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <DynamicDataGrid data={sampleData} />
          </Grid>
          <Grid item xs={12} sm={12} lg={8}></Grid>
        </Grid>
      )}
    </>
  );
};
