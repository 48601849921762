import { CircularProgress, Typography } from '@mui/material';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import React, { memo, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import './Map.scss';

const containerStyle = {
  width: '41.5%',
  height: 'calc(100% - 15px)',
  border: '1px solid #e0e0e0',
  borderRadius: '20px',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
};

let markersRefArray = [];
const libraries = ['marker', 'places'];

export const Map = ({ data, coordsToMoveTo, noRoll, uuidHighlited, googleApiLoaded }) => {
  const [bounds, setBounds] = useState([]);
  const [map, setMap] = React.useState(null);
  const [zoomLevel, setZoomLevel] = useState(8);
  const [highlight, setHighlight] = useState();
  const [markers, setMarkers] = useState([]);

  const mapRef = useRef();
  const navigate = useNavigate();

  const center = {
    lat: 46.776480866,
    lng: 3
  };

  useEffect(() => {
    if (bounds && bounds.length > 0 && map) {
      const boundsToFit =
        bounds.length > 0
          ? new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bounds[0].lat, bounds[0].lgt))
          : new window.google.maps.LatLngBounds(new window.google.maps.LatLng(46.776480866, 3));

      bounds.forEach((coord) => {
        boundsToFit.extend(new window.google.maps.LatLng(coord.lat, coord.lgt));
      });

      map.fitBounds(boundsToFit);
      map.setZoom(Math.min(map.getZoom(), map.maxDefaultZoom));
    }
  }, [noRoll]);

  useEffect(() => {
    if (uuidHighlited === '' || uuidHighlited === null) {
      setTimeout(() => {
        setHighlight(null);
      }, 10);
    } else {
      setTimeout(() => {
        if (map) {
          map.setTilt(1000);
          setHighlight(uuidHighlited);
          map.setZoom(Math.min(map.getZoom(), map.maxDefaultZoom));
        }
      }, 10);
    }
  }, [uuidHighlited]);

  useEffect(() => {
    if (coordsToMoveTo.length > 0 && map) {
      map.panTo(new window.google.maps.LatLng(coordsToMoveTo[0], coordsToMoveTo[1]));
      map.setZoom(Math.min(map.getZoom(), map.maxDefaultZoom));
    }
  }, [coordsToMoveTo]);

  useEffect(() => {
    const markers = [];
    const bds = [];

    data.forEach((product) => {
      // console.log(product.isBuilding);

      let url_img = '';

      if (product.isBuilding) {
        url_img = process.env.REACT_APP_BASEURL_MEDIAS + product.image_url;
      } else {
        url_img = product.image_url;
      }
      if (product.address) {
        markers.push({
          uuid: product.uuid,
          position: { lat: product.address.lat, lng: product.address.long },
          ...product
        });

        bds.push({
          lat: product.address.lat,
          lgt: product.address.long,
          street: product.address.street,
          city: product.address.city,
          uuid: product.uuid,
          image_url: url_img
        });
      }
    });

    setMarkers(markers);
    setBounds(bds);

    if (markersRefArray && markersRefArray.length > 0 && map) {
      markersRefArray.forEach((m, index) => {
        if (m) {
          m.marker.setMap(null);
          m.marker = null;
        }
      });
    }

    markersRefArray = [];
  }, [data]);

  useEffect(() => {
    if (bounds && bounds.length > 0 && map) {
      const boundsToFit =
        bounds.length > 0
          ? new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bounds[0].lat, bounds[0].lgt))
          : new window.google.maps.LatLngBounds(new window.google.maps.LatLng(46.776480866, 3));

      bounds.forEach((coord) => {
        boundsToFit.extend(new window.google.maps.LatLng(coord.lat, coord.lgt));
      });

      // map.maxZoom = 100;
      map.fitBounds(boundsToFit);
    }
  }, [bounds, map]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    version: 'beta',
    libraries
  });

  useEffect(() => {
    if (isLoaded === true) {
      setMap(mapRef.current.getInstance());
      googleApiLoaded(isLoaded);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (map) {
      setZoomLevel(8);
      map.setCenter(new window.google.maps.LatLng(center.lat, center.lng));

      map.maxDefaultZoom = 19;

      window.google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
        this.setZoom(Math.min(this.getZoom(), this.maxDefaultZoom));
      });
    }
  }, [map]);

  return (
    <>
      {loadError && <div>Error loading maps</div>}
      {!isLoaded && (
        <div className="MapLoader">
          <CircularProgress />
        </div>
      )}
      {isLoaded && (
        <>
          <GoogleMap
            ref={mapRef}
            mapContainerStyle={containerStyle}
            zoom={zoomLevel}
            center={center}
            id="map"
            maxZoom={8}
            options={{ fullscreenControl: false, disableDefaultUI: true, mapId: 'eab1bf735be76e31' }}
          >
            {markers.map((marker, index) => {
              return (
                <Marker key={index} uuid={marker.uuid} position={marker.position} map={map}>
                  <div
                    uuid={marker.uuid}
                    className={`marker-container ${highlight === marker.uuid ? 'highlighted-marker-container' : ''} ${
                      highlight !== null && highlight !== marker.uuid ? 'unhighlighted-marker-container' : ''
                    }`}
                    onMouseEnter={() => {
                      setHighlight(marker.uuid);
                    }}
                    onMouseLeave={() => {
                      setHighlight(null);
                    }}
                    onClick={() => {
                      if (marker.isBuilding === true) {
                        navigate(`/building/${marker.uuid}`);
                      } else {
                        navigate(`/product/${marker.uuid}`);
                      }
                    }}
                  >
                    <div
                      className={`logoMarker ${
                        highlight === marker.uuid || (highlight !== marker.uuid && highlight !== null)
                          ? 'highlighted-logoMarker'
                          : ''
                      }`}
                    ></div>
                    {marker.image_url && (
                      <img
                        alt={marker.uuid}
                        className={`imgProduct ${highlight === marker.uuid ? 'highlighted-img-product' : ''}`}
                        src={marker.image_url}
                      ></img>
                    )}

                    {!marker.image_url && (
                      <div
                        style={{
                          background:
                            'repeating-linear-gradient(-45deg,#0627461d,#0627461d 20px,#06274614 20px,#06274614 40px)'
                        }}
                        className={`imgProduct ${highlight === marker.uuid ? 'highlighted-img-product' : ''}`}
                      ></div>
                    )}

                    <Typography
                      className={`address ${highlight === marker.uuid ? 'highlighted-address' : ''}`}
                      sx={{ fontSize: 12 }}
                      color="text.primary"
                      gutterBottom
                    >
                      {marker.address.street}
                    </Typography>
                  </div>
                </Marker>
              );
            })}
          </GoogleMap>
        </>
      )}
    </>
  );
};

function Marker({ map, children, position, uuid }) {
  const markerRef = useRef();
  const rootRef = useRef();
  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement('div');
      rootRef.current = createRoot(container);
      markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
        position,
        content: container
      });

      markerRef.current.addListener('gmp-click', () => {});
    }
  }, []);

  useEffect(() => {
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
    markersRefArray.push({ uuid: uuid, marker: markerRef.current });
  }, [map, children, position]);

  useEffect(() => {
    return () => {
      if (markerRef.current) markerRef.current.removeEventListener('gmp-click');
    };
  }, []);
}

export default memo(Map);
