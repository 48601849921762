// DynamicDataGrid.js
import React from 'react';
import {
  DataGrid,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import { Box, Card, Typography, Pagination } from '@mui/material';
import '../../components/DocumentsList/DocumentsList.scss';

export const DynamicDataGrid = ({ data }) => {
  const columns: GridColDef[] =
    data.length > 0
      ? Object.keys(data[0])
          .filter((key) => key !== 'id')
          .map((key) => ({
            field: key,
            headerName: key.charAt(0).toUpperCase() + key.slice(1),
            flex: 1,
            sortable: true,
            renderCell: (params) => (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                <Typography variant="body2" sx={{ mt: '15px' }}>
                  {params.value}
                </Typography>
              </Box>
            )
          }))
      : [];

  const getRowId = (row) => row.id;
  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    if (pageCount === 1) return <div></div>;
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  return (
    <Card sx={{ borderRadius: '20px', maxHeight: 300 }}>
      <Box sx={{ height: 300, width: '100%' }}>
        <DataGrid
          className="DocumentsList"
          rows={data}
          columns={columns}
          getRowId={getRowId}
          initialState={{
            pagination: { paginationModel: { page: 0, pageSize: 4 } }
          }}
          components={{
            Pagination: CustomPagination
          }}
        />
      </Box>
    </Card>
  );
};
