import { Card, CardContent, Box, Typography, Chip } from '@mui/material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import Color from 'color';

export const Stats = ({ title, amount, text, percentageColor, chipText, info }) => {
  const backgroundColor = Color(percentageColor).alpha(0.25).string();
  return (
    <Card sx={{ borderRadius: '10px' }}>
      <CardContent sx={{ height: 259 }}>
        <Box display="flex" alignItems="center" sx={{ gap: '0.5vw' }}>
          <MapsHomeWorkIcon sx={{ mb: 6 }} />
          <Typography variant="h6" mb={6}>
            {title}
          </Typography>
        </Box>

        <Typography variant="h3" mb={6}>
          <Box fontWeight="fontWeightRegular" sx={{ pt: '4vh' }}>
            {amount} {text}
          </Box>
        </Typography>
        <Box display="flex" alignItems="center" sx={{ gap: '0.5vw', pt: '4vh' }}>
          <Chip
            sx={{ backgroundColor: backgroundColor, color: percentageColor, fontWeight: 'bold' }}
            label={chipText}
            size="small"
          />
          <Typography variant="body2" color="textSecondary">
            {info}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
