import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Modal,
  TextField,
  Typography,
  InputBase,
  FormControlLabel,
  Dialog,
  DialogTitle,
  Autocomplete,
  FormControl,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  useTheme
} from '@mui/material';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import Snackbar from '@mui/material/Snackbar';
import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { IProduct, ModalContentType } from '~/interfaces/product';
import { api, currentUser } from '~/api';
import { Map } from '~/app/components/Map/Map';
import { PortfolioCard } from '~/app/components/PortfolioCard/PortfolioCard';
import { appTheme } from '~/utils/theme';
import { WizzardCreateProduct } from '~/app/components/CreateProduct/WizzardCreateProduct';
import './ProductList.scss';
import auth from '~/app/components/Auth/AuthApi';
import packageInfo from '../../../../package.json';
import axios from 'axios';
import normalizedString from '~/utils/normalizedString';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

interface IAddProduct {
  street: string;
  city: string;
  country: string;
  zip_code: string;
  model_id?: string;
  reference?: string;
  data?: Record<string, never>;
}

const LotCheckBoxKey = 'lotCheckBox';
const WalletCheckBoxKey = 'walletCheckBox';
const EventsToCheckCheckBoxKey = 'eventsToCheckCheckBox';

export const ProductList = ({ setSpinnerOpen, spinnerOpen }) => {
  const initProductData = {
    street: '',
    city: '',
    country: 'France',
    zip_code: '',
    model_id: '',
    reference: '',
    data: {}
  };
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState({ isError: false, error: {} });
  const [productsList, setProductsList] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [filteredProductsData, setFilteredProductsData] = useState([]);
  const [addProductMode, setAddProductMode] = useState(false);
  const [newProductData, setNewProductData] = useState<IAddProduct>(initProductData);
  const [submit, setSubmit] = useState<boolean>(false);
  const [selectedIdProduct, setSelectedIdProduct] = useState('');
  const [selectedIdIsBuilding, setSelectedIdIsBuilding] = useState(false);
  const [coordsToMoveTo, setCoordsToMoveTo] = useState([]);
  const [originalBds, setOriginalBds] = useState<any>();
  const [mapObject, setMapObject] = useState<any>();
  const [cardIndexEntered, setCardIndexEntered] = useState<number>(-1);
  const [noRoll, setNoRoll] = useState<boolean>(true);
  const [batCheckBox, setBatCheckBox] = useState<boolean>(false);

  const [lotCheckBox, setLotCheckBox] = useState<boolean>(
    localStorage.getItem(LotCheckBoxKey) === 'false' ? false : true
  );

  const [walletCheckBox, setWalletCheckBox] = useState<boolean>(
    localStorage.getItem(WalletCheckBoxKey) === 'true' ? true : false
  );
  const [eventsToCheckCheckBox, setEventsToCheckCheckBox] = useState<boolean>(
    localStorage.getItem(EventsToCheckCheckBoxKey) === 'true' ? true : false
  );

  const [firstConection, setFirstConection] = useState<boolean>(true);
  const [displayMap, setDisplayMap] = useState<boolean>(true);
  const [contentHasBeenScrolled, setContentHasBeenScrolled] = useState<boolean>(false);
  const [uuidHighlited, setUuidHighlited] = useState<string>('');
  const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false);
  const [placeSelected, setPlaceSelected] = useState<any>(null);
  const [productByBuildings, setProductByBuildings] = useState<any[]>([]);
  const [productsByBuildingFolded, setProductsByBuildingFolded] = useState<any>({});

  const [uuidProductToAttribute, setUuidProductToAttribute] = useState<string>('');

  const [foldBuildings, setFoldBuildings] = useState([]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalContentType>();

  const [usersInOrganisation, setUsersInOrganisation] = useState([]);

  const [uuidUserToAttribute, setUuidUserToAttribute] = useState<string>('');

  const modalOpenRef = useRef(modalOpen);

  const [cancelCreationConfirmation, setCancelCreationConfirmation] = useState<boolean>(false);

  const [attributionDialogOpen, setAttributionDialogOpen] = React.useState(false);

  const handleCloseAttributionDialog = () => {
    setUuidUserToAttribute('');
    setAttributionDialogOpen(false);
    setUuidProductToAttribute('');
  };

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
    localStorage.setItem(LotCheckBoxKey, lotCheckBox.toString());
  }, [lotCheckBox]);

  useEffect(() => {
    localStorage.setItem(WalletCheckBoxKey, walletCheckBox.toString());
  }, [walletCheckBox]);

  useEffect(() => {
    localStorage.setItem(EventsToCheckCheckBoxKey, eventsToCheckCheckBox.toString());
  }, [eventsToCheckCheckBox]);

  const checkVersion = () => {
    if (window.location.pathname === '/' && modalOpenRef.current === false) {
      axios.get(window.location.origin + '/version.txt?' + Math.random()).then((res) => {
        const versionTxt = res.data.replace(/[\r\n]+/gm, '');
        console.log(versionTxt !== packageInfo.version);
        if (versionTxt !== packageInfo.version) {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    console.log('uuidUserToAttribute', uuidUserToAttribute);
  }, [uuidUserToAttribute]);

  useEffect(() => {
    modalOpenRef.current = modalOpen;
  }, [modalOpen]);

  const refreshProductsAndBuildings = () => {
    const requests = [];
    requests.push(api.product.search());
    requests.push(api.building.search());

    Promise.all(requests)
      .then((values) => {
        const buildings = values[1];
        const products = values[0];

        const sortedProductAndBuildings = [];

        const productByBuildings = [];

        const productByBuildingsUUID = {};

        const productsWithoutBuilding = [];

        buildings.forEach((building, index) => {
          productByBuildings.push({ building: building, products: [] });
          productByBuildingsUUID[building.uuid] = index;

          const buildingAndHisProducts = [];
          const buildingProducts = [];
          building.isBuilding = true;
          buildingAndHisProducts.push(building);

          products.forEach((product) => {
            if (product.uuid_building === building.uuid) {
              buildingAndHisProducts.push(product);
              buildingProducts.push(product);
            }
          });
          sortedProductAndBuildings.push(...buildingAndHisProducts);
          productByBuildings[index].products = buildingProducts;
        });

        if (buildings.length === 0) {
          products.forEach((product) => {
            sortedProductAndBuildings.push(product);
          });
        } else {
          products.forEach((product) => {
            if (!product.uuid_building) {
              sortedProductAndBuildings.push(product);
            }
          });
        }

        setProductsData(sortedProductAndBuildings);
        setProductsList(sortedProductAndBuildings);
        setFilteredProductsData(sortedProductAndBuildings);
        setProductByBuildings(productByBuildings);
        setProductsByBuildingFolded(productByBuildingsUUID);
        setSpinnerOpen(false);
      })
      .catch((error) => {
        setError({ isError: true, error });
        setSpinnerOpen(false);
      })
      .finally(() => {
        setSpinnerOpen(false);
      });
  };

  useEffect(() => {
    refreshProductsAndBuildings();

    api.organizations.get(auth.getOrganizationUUID()).then((res) => {
      console.log('organization', res);
      const users = [];
      res.users.forEach((user) => {
        console.log(user);
        users.push({ label: `${user.first_name} ${user.last_name}`, value: user.uuid });
      });
      setUsersInOrganisation(users);
    });

    let intervalId = setInterval(checkVersion, 1000 * 60);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const toggleShowProducts = (buildingUUID) => {
    const newFoldBuildings = [];
    let isPresent = false;
    let currentUUID = null;
    foldBuildings.forEach((uuid) => {
      if (uuid === buildingUUID) {
        currentUUID = uuid;
        isPresent = true;
      } else if (uuid !== buildingUUID) {
        newFoldBuildings.push(uuid);
      }
    });
    if (!isPresent) newFoldBuildings.push(buildingUUID);
    setFoldBuildings(newFoldBuildings);
  };

  const checkHasProducts = (buildingUUID) => {
    if (
      !productByBuildings ||
      !productsByBuildingFolded ||
      !productByBuildings[productsByBuildingFolded[buildingUUID]] ||
      !productByBuildings[productsByBuildingFolded[buildingUUID]].products
    ) {
      return false;
    }

    return productByBuildings[productsByBuildingFolded[buildingUUID]].products.length > 0;
  };

  useEffect(() => {
    if (productsData && productsData.length > 0) {
      const filteredData = [];
      let hasProductsInWallet = false;
      productsData.forEach((product: IProduct) => {
        let isInMyWallet = false;
        let hasEventsToCheck = false;
        if (walletCheckBox) {
          if (product.users_in_charge && product.users_in_charge.length > 0) {
            product.users_in_charge.forEach((user) => {
              if (user.uuid_user === currentUser.uuid_user) {
                isInMyWallet = true;
              }
            });
          }
        }

        if (eventsToCheckCheckBox) {
          if (product.nb_events_to_qualify && product.nb_events_to_qualify > 0) {
            hasEventsToCheck = true;
          }
        }

        if ((isInMyWallet || !walletCheckBox) && (hasEventsToCheck || !eventsToCheckCheckBox)) {
          if (batCheckBox) {
            if (product.isBuilding) {
              filteredData.push(product);
            }
          }

          if (lotCheckBox) {
            if (!product.isBuilding) {
              setBatCheckBox(false);
              filteredData.push(product);
            }
          }
        }

        if (isInMyWallet) {
          hasProductsInWallet = true;
        }

        if (!lotCheckBox && !eventsToCheckCheckBox && !walletCheckBox) {
          setBatCheckBox(true);
        }
      });

      if (!hasProductsInWallet && firstConection) {
        setWalletCheckBox(false);
      }

      const newFilteredFolderdData = [];

      const productsByBuildingFolded = [];

      if (foldBuildings.length === 0) {
        newFilteredFolderdData.push(...filteredData);
      } else {
        foldBuildings.forEach((uuidBuilding, index) => {
          productsByBuildingFolded.push([]);

          filteredData.forEach((product) => {
            if (product.uuid_building === uuidBuilding) {
              productsByBuildingFolded[index].push(product);
            }
          });
        });

        filteredData.forEach((product) => {
          if (product.isBuilding) {
            newFilteredFolderdData.push(product);
          }

          let hasToBeInvisible = false;

          foldBuildings.forEach((uuidBuilding, index) => {
            if (product.uuid_building === uuidBuilding || product.isBuilding) {
              hasToBeInvisible = true;
            }
          });

          if (!hasToBeInvisible) {
            newFilteredFolderdData.push(product);
          }
        });
      }

      setFilteredProductsData(newFilteredFolderdData);
    }
  }, [batCheckBox, lotCheckBox, walletCheckBox, eventsToCheckCheckBox, foldBuildings, productsData]);

  useEffect(() => {
    if (!spinnerOpen && !error.isError && productsData) {
      const filteredList = filteredProductsData.filter((item: IProduct) => {
        const normalizedSearch = normalizedString(search);

        return item.address
          ? normalizedString(item.address.street).indexOf(normalizedSearch) >= 0 ||
              normalizedString(item.address.city).indexOf(normalizedSearch) >= 0 ||
              normalizedString(item.address.zip_code).indexOf(normalizedSearch) >= 0 ||
              (item.tenant && normalizedString(item.tenant.name).indexOf(normalizedSearch) >= 0) ||
              (item.tenant && normalizedString(item.tenant.company_name).indexOf(normalizedSearch) >= 0) ||
              (item.owner && normalizedString(item.owner.name).indexOf(normalizedSearch) >= 0) ||
              (item.owner && normalizedString(item.owner.company_name).indexOf(normalizedSearch) >= 0) ||
              (item.name && normalizedString(item.name).indexOf(normalizedSearch) >= 0) ||
              (item.id_internal && normalizedString(item.id_internal).indexOf(normalizedSearch) >= 0)
          : item;
      });
      setProductsList(filteredList);
    }
  }, [search, spinnerOpen, productsData, filteredProductsData]);

  useEffect(() => {
    if (placeSelected !== null) {
      const id = String(placeSelected.place_id);
      new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
        { placeId: id, fields: ['address_components'] },
        (details) => {
          let postcode = null;
          let country = null;
          let locality = null;
          let street_number = null;
          let route = null;
          details?.address_components?.forEach((entry) => {
            if (entry.types?.[0] === 'postal_code') {
              postcode = entry.long_name;
            }
            if (entry.types?.[0] === 'country') {
              country = entry.long_name;
            }
            if (entry.types?.[0] === 'locality') {
              locality = entry.long_name;
            }
            if (entry.types?.[0] === 'street_number') {
              street_number = entry.long_name;
            }
            if (entry.types?.[0] === 'route') {
              route = entry.long_name;
            }
          });
          const street = street_number ? street_number + ' ' + route : route;
          const address = {
            street: street,
            city: locality,
            country: country,
            zip_code: postcode
          };
          setNewProductData(address);
        }
      );
    }
  }, [placeSelected]);

  const handleClose = () => {
    setOpenSnackBar(false);
    setOpen(false);
  };

  const setInitialCoords = (bds, map, center) => {
    setOriginalBds(bds);
    setMapObject(map);
  };

  const handleCreateProduct = () => {
    setModalType('CreateProduct');
    setModalOpen(true);
  };

  const handleCancel = () => {
    setAddProductMode(false);
    setOpen(false);
  };

  const handleSubmitNewProduct = () => {
    setSubmit(true);
    const newProduct = {
      id_internal: newProductData.reference,
      address: {
        street: newProductData.street,
        city: newProductData.city,
        zip_code: newProductData.zip_code,
        country: newProductData.country
      },
      matterport_scan: {
        model_id: newProductData.model_id,
        activated: true
      },
      data: {}
    };
  };

  const handleMouseEnter = (e) => {
    setCardIndexEntered(Number(e.currentTarget.dataset.index));
    if (e.currentTarget.dataset.lat) setCoordsToMoveTo([e.currentTarget.dataset.lat, e.currentTarget.dataset.lgt]);
    setUuidHighlited(e.currentTarget.dataset.linkid);
    setNoRoll(false);
  };

  const handleMouseLeave = (e) => {
    if (originalBds) setCoordsToMoveTo([originalBds.getCenter().lat(), originalBds.getCenter().lng()]);
    if (mapObject !== undefined && mapObject !== null) mapObject.fitBounds(originalBds);
    setCardIndexEntered(-1);
    setUuidHighlited('');
    setNoRoll(true);
  };

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '1390px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
  };

  const handleSuppressProduct = (idProduct, isBuilding = false) => {
    setSelectedIdIsBuilding(isBuilding);
    setSelectedIdProduct(idProduct);
    setOpen(true);
  };

  const handleValidate = () => {
    if (selectedIdIsBuilding) {
      api.building.delete(selectedIdProduct).then((resp) => {
        const newData = [];
        productsData.forEach((product) => {
          if (product.uuid !== selectedIdProduct) newData.push(product);
        });
        setProductsData(newData);
        setSelectedIdProduct('');
        setProductsList(newData);
        setFilteredProductsData(newData);
        setOpen(false);
      });
    } else {
      api.product.delete(selectedIdProduct).then((resp) => {
        // deleteProduct(selectedIdProduct).then((resp) => {
        const newData = [];
        productsData.forEach((product) => {
          if (product.uuid !== selectedIdProduct) newData.push(product);
        });
        setProductsData(newData);
        setSelectedIdProduct('');
        setProductsList(newData);
        setFilteredProductsData(newData);
        setOpen(false);
      });
    }
  };

  const modalClosed = (str: string) => {
    if (productsData === null) return;
  };

  const handleChangeLotCheckBox = (e) => {
    setLotCheckBox(e.target.checked);
  };

  const handleChangeBuildingCheckBox = (e) => {
    setFoldBuildings([]);
    setBatCheckBox(e.target.checked);
  };

  const handleChangeWalletCheckBox = (e) => {
    setWalletCheckBox(e.target.checked);
    setFirstConection(false);
  };
  const handleChangeEventsToCheckCheckBox = (e) => {
    setEventsToCheckCheckBox(e.target.checked);
    setFirstConection(false);
  };

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
  };

  const mapToggler = () => {
    setDisplayMap(!displayMap);
  };

  useEffect(() => {
    if (!matchesMd) {
      setDisplayMap(false);
    }
  }, [matchesMd]);

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = React.useState('');

  const handleContentModal = (type: ModalContentType): ReactNode => {
    switch (type) {
      case 'CreateProduct':
        return (
          <div style={{ padding: '25px' }}>
            <WizzardCreateProduct
              setSpinnerOpen={setSpinnerOpen}
              spinnerOpen={false}
              setModalOpen={setModalOpen}
              placeSelected={placeSelected}
              setPlaceSelected={setPlaceSelected}
              closeModal={() => setModalOpen(false)}
              cancelCreationConfirmation={cancelCreationConfirmation}
              setCancelCreationConfirmation={setCancelCreationConfirmation}
            ></WizzardCreateProduct>
          </div>
        );
    }
  };

  const handleConfirmClose = () => {
    setCancelCreationConfirmation(true);
  };

  const inCharge = (item) => {
    let inCharge = false;
    if (item.users_in_charge) {
      item.users_in_charge.forEach((user: any) => {
        if (user.uuid_user === currentUser.uuid_user) {
          inCharge = true;
        }
      });
    }
    return inCharge;
  };

  return (
    <Box
      className="ProductList"
      sx={{
        width: 1
      }}
    >
      <div>
        <BasicModal
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
          confirmClose={true}
          handleConfirm={handleConfirmClose}
        >
          {handleContentModal(modalType)}
        </BasicModal>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{
          backdropFilter: 'blur(5px)'
        }}
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">{`Suppression de ${selectedIdIsBuilding ? 'bâtiment' : 'produit'}`}</h2>
          <p id="parent-modal-description">{`Êtes vous sur de vouloir supprimer ce ${
            selectedIdIsBuilding ? 'bâtiment' : 'produit'
          }`}</p>
          <div className="BasicModal__close" onClick={handleClose}>
            <CloseIcon />
          </div>
          <Grid item xs={12} md={6}>
            <Button color={'primary'} variant="outlined" onClick={handleCancel}>
              Annuler
            </Button>
            &nbsp;
            <Button variant="contained" color={'primary'} onClick={handleValidate}>
              Valider
            </Button>
          </Grid>
        </Box>
      </Modal>
      <div
        style={{
          display: 'flex',
          maxHeight: 'calc(100% - 100px)',
          height: 'calc(100% - 100px)',
          minHeight: 'calc(100% - 100px)',
          width: 'calc(100% - 52px)',
          maxWidth: '1488px',
          position: 'absolute',
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            display: 'flex',
            width: displayMap ? '58.5%' : '100%',
            minWidth: displayMap ? '58.5%' : '100%',
            flexDirection: 'column'
          }}
        >
          <div>
            <Box
              sx={{
                width: displayMap ? null : 1,
                display: 'flex',
                alignItems: 'center'
              }}
              style={{ paddingRight: '17px' }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h1" sx={{ color: 'primary.main', paddingTop: !matchesMd ? '5px' : '15px' }}>
                  Portefeuille
                </Typography>
              </Box>
              <div style={{ paddingRight: '15px' }}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ color: 'white', borderRadius: '20px', paddingTop: '8px' }}
                  onClick={handleCreateProduct}
                  size="small"
                >
                  <AddOutlinedIcon fontSize="small" style={{ paddingBottom: '2px' }} />
                  {!matchesMd ? (
                    <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp;Biens</span>
                  ) : (
                    <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp; Ajouter un bien</span>
                  )}
                </Button>
              </div>
            </Box>
            <Box
              sx={{
                my: !matchesMd ? 0 : 4,
                width: displayMap ? null : 1
              }}
            >
              {addProductMode && (
                <>
                  {placeSelected === null && (
                    <div
                      style={{ paddingRight: displayMap ? '17px' : '0px', paddingBottom: !matchesMd ? '5px' : '15px' }}
                    >
                      <Grid container spacing={1.5}>
                        <Grid item xs={12} md={12}>
                          <TextField
                            fullWidth
                            required
                            onChange={(e) => {
                              setPlaceSelected(null);
                            }}
                            id="search-input"
                          ></TextField>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {placeSelected && (
                    <div style={{ paddingRight: displayMap ? '17px' : '0px' }}>
                      <Grid container spacing={1.5}>
                        <Grid item xs={12} md={12}>
                          <TextField
                            label="Adresse"
                            fullWidth
                            required
                            error={submit && newProductData.street.length === 0}
                            helperText={submit ? (newProductData.street.length !== 0 ? '' : 'Adresse non valide') : ''}
                            value={newProductData.street}
                            onChange={(e) => setNewProductData({ ...newProductData, street: e.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <TextField
                            label="Ville"
                            fullWidth
                            required
                            error={submit && newProductData.city.length === 0}
                            helperText={submit ? (newProductData.city.length !== 0 ? '' : 'Ville non valide') : ''}
                            value={newProductData.city}
                            onChange={(e) => setNewProductData({ ...newProductData, city: e.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            label="Code postal"
                            fullWidth
                            required
                            error={submit && newProductData.zip_code.length === 0}
                            helperText={submit ? (newProductData.zip_code.length !== 0 ? '' : 'zip non valide') : ''}
                            value={newProductData.zip_code}
                            onChange={(e) => setNewProductData({ ...newProductData, zip_code: e.target.value })}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            label="Pays"
                            fullWidth
                            required
                            error={submit && newProductData.country.length === 0}
                            helperText={submit ? (newProductData.country.length !== 0 ? '' : 'pays non valide') : ''}
                            value={newProductData.country}
                            onChange={(e) => setNewProductData({ ...newProductData, country: e.target.value })}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>

                        {auth.isSuperAdmin() && (
                          <Grid item xs={12} md={4}>
                            <TextField
                              label="Modèle Matterport"
                              fullWidth
                              required={false}
                              value={newProductData.model_id}
                              onChange={(e) => setNewProductData({ ...newProductData, model_id: e.target.value })}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12} md={4}>
                          <TextField
                            label="Référence"
                            fullWidth
                            required={false}
                            value={newProductData.reference}
                            onChange={(e) => setNewProductData({ ...newProductData, reference: e.target.value })}
                          />
                        </Grid>
                      </Grid>

                      <div className="Auth__submit">
                        <Button color={'primary'} variant="outlined" onClick={handleCancel}>
                          Annuler
                        </Button>
                        &nbsp;
                        <Button color={'primary'} variant="contained" onClick={handleSubmitNewProduct}>
                          Valider
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Box>
            <div style={{ paddingRight: displayMap ? '17px' : '0px' }}>
              <Box
                sx={{
                  my: 1,
                  width: '99.8%'
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: appTheme.palette.primary[50],
                    border: `1px solid`,
                    borderColor: appTheme.palette.primary[100],
                    borderRadius: '20px',
                    display: 'flex',
                    height: !matchesMd ? '24px' : '36px',
                    fontSize: '16px',
                    width: '100%'
                  }}
                >
                  <InputBase
                    autoComplete="off"
                    placeholder="Propriétaire, locataire, référence, adresse…"
                    onChange={handleSearch}
                    value={search}
                    inputProps={{
                      sx: {
                        pl: 1.8,
                        '&::placeholder': {
                          // color: 'primary.main',
                          // opacity: 1
                        }
                      }
                    }}
                    sx={{ flex: 1 }}
                  />
                  <SearchOutlinedIcon
                    sx={{
                      color: 'primary.main',
                      ml: 2,
                      mr: 1
                    }}
                    fontSize="medium"
                  />
                </div>
              </Box>
              <Grid container alignItems="flex-end">
                <Grid
                  item
                  xs={12}
                  sm
                  style={
                    contentHasBeenScrolled
                      ? {
                          zIndex: 999,
                          boxShadow: '-6px 14px 18px -18px #000000'
                        }
                      : {}
                  }
                >
                  <Box sx={{ flex: displayMap ? null : 1, mb: !matchesMd ? 0 : 4 }}>
                    <Typography
                      sx={{ marginBottom: !matchesMd ? 0 : 2, color: 'primary.main', opacity: 1, textAlign: 'right' }}
                    >
                      {productsList.length} Résultats
                    </Typography>

                    <Grid container>
                      <Grid item xs={9}>
                        <Typography variant="body1" sx={{ color: 'primary.main' }}>
                          <FormControlLabel
                            sx={{ pr: '15px' }}
                            control={
                              <Checkbox
                                checked={walletCheckBox}
                                onChange={handleChangeWalletCheckBox}
                                id="port"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                              />
                            }
                            label="Mon portefeuille"
                          />

                          <FormControlLabel
                            sx={{ pr: '15px' }}
                            control={
                              <Checkbox
                                checked={eventsToCheckCheckBox}
                                onChange={handleChangeEventsToCheckCheckBox}
                                id="eve"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                              />
                            }
                            label={'Évènements à traiter'}
                          />

                          <FormControlLabel
                            sx={{ pr: '15px' }}
                            control={
                              <Checkbox
                                checked={lotCheckBox}
                                onChange={handleChangeLotCheckBox}
                                id="lot"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                              />
                            }
                            label={'Lots'}
                          />
                        </Typography>
                      </Grid>
                      {matchesMd && (
                        <Grid item xs={3} md={3} lg={3} style={{ textAlign: 'right' }}>
                          <Button
                            color="primary"
                            size="medium"
                            endIcon={<MapOutlinedIcon />}
                            variant="contained"
                            onClick={mapToggler}
                          >
                            {!displayMap ? 'Afficher la carte' : 'Masquer la carte'}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            onScroll={(e: any) => {
              if (e.target.scrollTop > 0) {
                setContentHasBeenScrolled(true);
              } else {
                setContentHasBeenScrolled(false);
              }
            }}
            sx={{
              overflow: 'scroll',
              '-ms-overflow-style': 'none' /* Pour IE et Edge */,
              'scrollbar-width': 'none' /* Pour Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none' /* Pour WebKit (Chrome, Safari) */
              }
            }}
          >
            <Grid container justifyContent="space-around">
              {productsList.map((item: IProduct, index: number) => {
                return (
                  <Grid item key={index} style={{ padding: '10px', paddingLeft: '0px', transition: 'all 1s' }}>
                    <PortfolioCard
                      key={`cartes-${index}`}
                      data={{
                        title: item.address && item.address.building_name ? item.address.building_name : '',
                        subtitle: item.address && item.address.street ? item.address.street : '',
                        description:
                          item.address && item.address.city && item.address.zip_code
                            ? item.address.zip_code + ' ' + item.address.city
                            : '',
                        index: index,
                        isBuilding: item.isBuilding ? item.isBuilding : false,
                        links: item.links,
                        image: item.image_url,
                        linkId: item.uuid,
                        obj: item,
                        nb_events_to_qualify: item.nb_events_to_qualify,
                        isSuperAdmin: auth.isSuperAdmin(),
                        handleSuppressProduct: handleSuppressProduct,
                        onMouseEnter: handleMouseEnter,
                        onMouseLeave: handleMouseLeave,
                        geocoords: item.address ? [item.address.lat, item.address.long] : [],
                        reference: item.id_internal,
                        openSnackBar: setOpenSnackBar,
                        openSnackBarMessage: setOpenSnackBarMessage,
                        setSpinnerOpen: setSpinnerOpen,
                        usersInCharge: item.users_in_charge,
                        isInMyWallet: inCharge(item),
                        name: item.name,
                        toggleShowProducts: toggleShowProducts,
                        isFolded: foldBuildings.includes(item.uuid),
                        hasProducts: checkHasProducts,
                        setAttributionDialogOpen: setAttributionDialogOpen,
                        setUuidProductToAttribute: setUuidProductToAttribute
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </div>

        {displayMap && !error.isError && (
          <Map
            data={productsList}
            coordsToMoveTo={coordsToMoveTo}
            noRoll={noRoll}
            uuidHighlited={uuidHighlited}
            googleApiLoaded={setGoogleApiLoaded}
          />
        )}
      </div>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleClose}
        message={openSnackBarMessage}
        ContentProps={{
          sx: {
            background: appTheme.palette.primary.main
          }
        }}
      />

      {error.isError && <div className="ProductList__error">{`Une erreur s'est produite`}</div>}

      <Dialog
        onClose={handleCloseAttributionDialog}
        open={attributionDialogOpen}
        PaperProps={{ sx: { width: '500px' } }}
      >
        <DialogTitle>
          <Typography variant="h4">
            <PermIdentityIcon style={{ fontSize: '30px', position: 'relative', top: '5px' }} />
            Attribuer à un utilisateur
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={12} sx={{ pt: '20px' }}>
              <Autocomplete
                onChange={(event, newValue) => {
                  if (newValue) {
                    console.log(newValue);
                    setUuidUserToAttribute(newValue.value);
                  }
                }}
                options={usersInOrganisation}
                renderInput={(params) => <TextField {...params} label="Rechercher un utilisateur" fullWidth />}
              ></Autocomplete>
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item sx={{ pt: '10px' }}>
              <Button
                onClick={() => {
                  setAttributionDialogOpen(false);
                  setUuidUserToAttribute('');
                  setUuidProductToAttribute('');
                }}
                variant="outlined"
              >
                Annuler
              </Button>
            </Grid>
            <Grid item sx={{ pt: '10px' }}>
              <Button
                disabled={uuidUserToAttribute === ''}
                variant="contained"
                onClick={() => {
                  api.users.product
                    .assign(uuidUserToAttribute, uuidProductToAttribute)
                    .then((res) => {
                      console.log('response', res);
                    })
                    .catch((error) => {
                      console.log('error', error);
                    })
                    .finally(() => {
                      setAttributionDialogOpen(false);
                      setUuidUserToAttribute('');
                      setUuidProductToAttribute('');
                      refreshProductsAndBuildings();
                    });
                }}
              >
                valider
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
