import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { appTheme } from '~/utils/theme';
import { useEffect, useState, useContext } from 'react';
import { type_references } from '~/utils/references';
import auth from '~/app/components/Auth/AuthApi';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';

export const CreateInformations = ({
  dpeDatas,
  setDpeDatas,
  setNextStepPossible,
  productKind,
  setProductKind,
  buildingKind,
  setBuildingKind,
  setPropertyDatas,
  currentStep,
  setMatterPortId,
  buildingDatas,
  propertyNature,
  setPropertyNature
}) => {
  const [reference, setReference] = useState('');
  const [logementType, setLogementType] = useState(null);

  const [etage, setEtage] = useState('');
  const [porte, setPorte] = useState('');
  const [surface, setSurface] = useState('');
  const [hauteur, setHauteur] = useState('');
  const [n_lot, setN_Lot] = useState('');
  const [lot_associated, setLot_associated] = useState('');
  const [furnished, setFurnished] = useState(null);
  const [warmingType, setWarmingType] = useState('');
  const [waterWarmingType, setWaterWarmingType] = useState('');
  const [dpeNumber, setDpeNumber] = useState('');
  const [energySticker, setEnergySticker] = useState('');
  const [gesSticker, setGesSticker] = useState('');
  const [model_id, setModel_id] = useState('');

  const [productNatureDisabled, setProductNatureDisabled] = useState(false);

  const [buildingKinds, setBuildingKinds] = useState(null);

  const { referencial } = useContext(AppContext);

  useEffect(() => {
    // Appartement
    if (currentStep === 1) {
      if (productKind === 1) {
        if (
          logementType !== '' &&
          surface !== '' &&
          n_lot !== '' &&
          furnished !== '' &&
          warmingType !== '' &&
          waterWarmingType !== ''
        ) {
          setNextStepPossible(true);
        } else {
          setNextStepPossible(true);
        }
      } else {
        if (surface !== '' && n_lot !== '' && furnished !== '' && warmingType !== '' && waterWarmingType !== '') {
          setNextStepPossible(true);
        } else {
          setNextStepPossible(true);
        }
      }
    }
  }, [
    productKind,
    logementType,
    surface,
    n_lot,
    furnished,
    warmingType,
    waterWarmingType,
    dpeNumber,
    energySticker,
    model_id,
    setNextStepPossible,
    currentStep
  ]);

  const changeReferenceHandler = (event) => {
    setReference(event.target.value);

    setPropertyDatas((prev) => ({
      ...prev,
      id_internal: event.target.value
    }));
  };

  useEffect(() => {
    if (referencial) {
      setBuildingKinds(_.sortBy(referencial['building.kind'], ['id']));
    }
  }, [referencial]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        logement_type: logementType
      }
    }));
  }, [logementType, setPropertyDatas]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        ceiling_height: Number(hauteur)
      }
    }));
  }, [hauteur]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        furnished: furnished
      }
    }));
  }, [furnished]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        heating_communal: warmingType
      }
    }));
  }, [warmingType]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        ecs_communal: waterWarmingType
      }
    }));
  }, [waterWarmingType]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        lot_number: n_lot
      }
    }));
  }, [n_lot]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        surface: Number(surface)
      }
    }));
  }, [surface]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        lot_associated: lot_associated
      }
    }));
  }, [lot_associated]);

  useEffect(() => {
    if (dpeDatas !== null) {
      if (dpeDatas?.body?.living_space) {
        const surface = Math.round(dpeDatas?.body?.living_space * 100) / 100;
        setSurface(surface.toString());
      }

      if (dpeDatas?.body?.ceiling_height) {
        const hauteur = Math.round(dpeDatas?.body?.ceiling_height * 100) / 100;
        setHauteur(hauteur.toString());
      }

      if (dpeDatas?.body?.dpe_number) {
        setDpeNumber(dpeDatas.body.dpe_number);
        setPropertyDatas((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            dpe_number: dpeDatas.body.dpe_number
          }
        }));
      }

      if (dpeDatas?.body?.energy_sticker) {
        setEnergySticker(dpeDatas.body.energy_sticker);
        setPropertyDatas((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            energy_sticker: dpeDatas.body.energy_sticker
          }
        }));
      }

      if (dpeDatas?.body?.ges_sticker) {
        setGesSticker(dpeDatas.body.ges_sticker);
        setPropertyDatas((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            ges_sticker: dpeDatas.body.ges_sticker
          }
        }));
      }

      if (dpeDatas?.body?.energy_result) {
        setPropertyDatas((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            energy_result: Math.floor(dpeDatas.body.energy_result)
          }
        }));
      }

      if (dpeDatas.body.ges_result) {
        setPropertyDatas((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            ges_result: Math.floor(dpeDatas.body.ges_result)
          }
        }));
      }

      if (dpeDatas.body.done_at) {
        setPropertyDatas((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            done_at: dpeDatas.body.done_at
          }
        }));
      }

      if (dpeDatas.body.expire_at) {
        setPropertyDatas((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            expire_at: dpeDatas.body.expire_at
          }
        }));
      }

      if (dpeDatas['Type_installation_ECS_(général)']) {
        setWaterWarmingType(dpeDatas['Type_installation_ECS_(général)']);
      }

      if (dpeDatas['Type_installation_chauffage']) {
        setWarmingType(dpeDatas['Type_installation_chauffage']);
      }
    }
  }, [dpeDatas, propertyNature]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        paddingLeft: '30px',
        '-ms-overflow-style': 'none' /* Pour IE et Edge */,
        'scrollbar-width': 'none' /* Pour Firefox */,
        '&::-webkit-scrollbar': {
          display: 'none' /* Pour WebKit (Chrome, Safari) */
        }
      }}
      style={{
        maxHeight: 'calc(100vh - 190px)',
        overflow: 'scroll'
      }}
    >
      <Grid item xs={8}>
        <h3 style={{ color: appTheme.palette.primary.main, marginTop: '0px' }}>Informations</h3>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {dpeDatas &&
              dpeDatas["Complément_d'adresse_logement"] &&
              dpeDatas["Complément_d'adresse_logement"] !== undefined &&
              (dpeDatas["Complément_d'adresse_logement"] !== null && dpeDatas["Complément_d'adresse_logement"]) !==
                '' && (
                <Typography gutterBottom component="div" sx={{ pt: '0px', pb: '0px' }}>
                  Complément d'adresse logement issue du DPE : {dpeDatas["Complément_d'adresse_logement"]}
                </Typography>
              )}
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size={'small'}
              autoComplete="off"
              value={reference}
              onChange={changeReferenceHandler}
              label="Référence interne"
            ></TextField>
          </Grid>

          <Grid item xs={6} style={{ display: auth.isSuperAdmin() ? 'block' : 'none' }}>
            <TextField
              size="small"
              label="Modèle Matterport"
              value={model_id}
              onChange={(e) => {
                setModel_id(e.target.value);

                if (buildingDatas.kind === 2) {
                  setMatterPortId(e.target.value);
                } else {
                  setPropertyDatas((prev) => ({
                    ...prev,
                    matterport_scan: {
                      ...prev.matterport_scan,
                      model_id: e.target.value
                    }
                  }));
                }
              }}
              fullWidth
            />
          </Grid>
          {(productKind === '1' || productKind === 1) && (
            <>
              <Grid item xs={12} sx={{ pt: '15px' }}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Type de logement
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={logementType}
                    label="Type de logement"
                    onChange={(e) => {
                      setLogementType(Number(e.target.value));
                    }}
                    size="small"
                  >
                    {type_references['logement_type'].map((type, index) => {
                      return (
                        <MenuItem key={index} value={index.toString()}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={6} sx={{ pt: '15px' }}>
            <TextField
              type="number"
              // required
              size="small"
              autoComplete="off"
              fullWidth
              disabled={dpeDatas?.body?.living_space ? true : false}
              id="surface"
              value={surface}
              onChange={(e) => setSurface(e.target.value)}
              label="Surface habitable"
              InputProps={{
                endAdornment: 'm2'
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6} sx={{ pt: '15px' }}>
            <TextField
              type="number"
              disabled={dpeDatas?.body?.ceiling_height ? true : false}
              size="small"
              autoComplete="off"
              fullWidth
              id="hauteur"
              value={hauteur}
              onChange={(e) => {
                setHauteur(e.target.value);
              }}
              label="Hauteur sous plafond"
              InputProps={{
                endAdornment: 'm'
              }}
            ></TextField>
          </Grid>

          <Grid item xs={4} sx={{ pt: '15px' }}>
            <TextField
              // required
              size="small"
              autoComplete="off"
              fullWidth
              id="n_lot"
              value={n_lot}
              onChange={(e) => setN_Lot(e.target.value)}
              label="N° de lot"
            ></TextField>
          </Grid>
          <Grid item xs={8} sx={{ pt: '15px' }}>
            <TextField
              size="small"
              autoComplete="off"
              fullWidth
              id="lot_associated"
              value={lot_associated}
              onChange={(e) => setLot_associated(e.target.value)}
              label="Lot(s) associé(s)"
            ></TextField>
          </Grid>
          <Grid item xs={12} sx={{ pt: '15px' }}>
            <FormControl sx={{ pt: '15px' }}>
              <FormLabel id="demo-row-radio-buttons-group-label">Meublé :</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                value={furnished}
                name="row-radio-buttons-group"
                onChange={(event) => {
                  setFurnished(event.target.value === 'false' ? false : true);
                }}
              >
                <FormControlLabel value={'true'} control={<Radio />} label="Oui" />
                <FormControlLabel value={'false'} control={<Radio />} label="Non" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ pt: '15px' }}>
            <FormControl
              sx={{ pt: '15px', display: 'inline-block' }}
              disabled={dpeDatas && dpeDatas['Type_énergie_principale_chauffage']}
            >
              <FormLabel id="demo-row-radio-buttons-group-label">Type de chauffage :</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                value={warmingType}
                name="row-radio-buttons-group"
                onChange={(event) => {
                  setWarmingType((event.target as HTMLInputElement).value);
                }}
              >
                <FormControlLabel value={'Collectif'} control={<Radio />} label="Collectif" />
                <FormControlLabel value={'Individuel'} control={<Radio />} label="Individuel" />
              </RadioGroup>
            </FormControl>
            {dpeDatas && dpeDatas['Type_énergie_principale_chauffage'] && (
              <Typography
                variant="body1"
                color="initial"
                sx={{ display: 'inline-block', top: '46px', position: 'relative' }}
              >
                {dpeDatas['Type_énergie_principale_chauffage']}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sx={{ pt: '15px' }}>
            <FormControl
              sx={{ pt: '15px', display: 'inline-block' }}
              disabled={dpeDatas && dpeDatas['Type_installation_ECS_(général)'] ? true : false}
            >
              <FormLabel id="demo-row-radio-buttons-group-label">Production d'eau chaude :</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                value={waterWarmingType}
                name="row-radio-buttons-group"
                onChange={(event) => {
                  setWaterWarmingType((event.target as HTMLInputElement).value);
                }}
              >
                <FormControlLabel value={'Collectif'} control={<Radio />} label="Collectif" />
                <FormControlLabel value={'Individuel'} control={<Radio />} label="Individuel" />
              </RadioGroup>
            </FormControl>

            {dpeDatas && dpeDatas['Type_énergie_principale_ECS'] && (
              <Typography
                variant="body1"
                color="initial"
                sx={{ display: 'inline-block', top: '46px', position: 'relative' }}
              >
                {dpeDatas['Type_énergie_principale_ECS']}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateInformations;
