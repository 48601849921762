import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Card, CardHeader, CardContent, useTheme } from '@mui/material';

export const LinesChart = ({ title, labels, info, data }) => {
  const theme = useTheme();

  return (
    <Card sx={{ borderRadius: '10px' }}>
      <CardHeader title={title} />
      <CardContent>
        <LineChart
          width={500}
          height={200}
          series={[{ data: data, label: info, color: theme.palette.primary.main }]}
          xAxis={[{ scaleType: 'point', data: labels }]}
        />
      </CardContent>
    </Card>
  );
};
