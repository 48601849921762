import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { appTheme } from '~/utils/theme';
import AppRouter from './AppRouter';
import { AppContext } from '~/utils/context/AppContext';
import { useEffect, useState } from 'react';
import { api } from '~/api';
import axios from 'axios';
import packageInfo from '../../package.json';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export default function App() {
  const [referencial, setReferencial] = useState(null);
  const [equipmentReferencial, setequipmentReferencial] = useState(null);

  useEffect(() => {
    api.referencial.get().then((res) => {
      setReferencial(res);
    });
    api.referencial.equipments.get().then((res) => {
      setequipmentReferencial(res);
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div style={{ height: '100%' }}>
        <AppContext.Provider value={{ referencial: referencial, equipmentReferencial: equipmentReferencial }}>
          <ThemeProvider theme={appTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <AppRouter />
            </LocalizationProvider>
          </ThemeProvider>
        </AppContext.Provider>
        <span
          style={{
            position: 'fixed',
            zIndex: 99999999999999999999,
            bottom: 0,
            right: 0,
            fontSize: '12px',
            padding: '2px',
            margin: '2px',
            color: 'grey'
          }}
        >{`v${packageInfo.version}`}</span>
      </div>
    </QueryClientProvider>
  );
}
